/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import { defineNuxtRouteMiddleware, navigateTo, useNuxtApp } from '#app'
import useService from '~/src/UserInterface/App/composables/Container/useService'
import useUserState from '~/src/UserInterface/Identity/composables/useUserState'

/**
 * This middleware must be called on the 2fa page
 *
 * Prerequisites for this middleware:
 * - None
 *
 * This middleware checks if the user authentication process has been initiated:
 * - If the process is not initiated, the user is redirected to the login page.
 */
export default defineNuxtRouteMiddleware(async () => {
  const nuxtApp = useNuxtApp()
  const logger = useService('logger')
  logger.info('[AUTH_INITIATED] Middleware fired')

  const { isAuthenticationProcessInitiated } = useUserState()
  if (!isAuthenticationProcessInitiated.value) {
    logger.info('[AUTH_INITIATED] Process is not initiated, redirecting')

    // eslint-disable-next-line ts/no-unsafe-argument
    return navigateTo(nuxtApp.$localePath('identity-Login'))
  }
})
